import React from 'react';
import {
  ContainerWidgetItem,
  useGetWidgetData,
} from '@leagueplatform/masonry-api';
import { MASONRY } from 'common/constants';
import { SkeletonBox } from '@leagueplatform/web-common-components';
import {
  GDSIconProps,
  GDSStatus,
  Icon,
  ParagraphText,
  StatusBanner,
  TextAction,
} from '@leagueplatform/genesis-core';
import { isExternalUrl } from '@leagueplatform/web-common';
import { Link } from '@leagueplatform/routing';
import { useConfigProperty } from '@leagueplatform/core';
import {
  EVENT_NAME,
  EventProps,
  trackAnalyticsEvent,
} from '@leagueplatform/analytics';

type StatusBannerData = {
  description?: string;
  heading?: string;
  image?: string;
  image_alt_text?: string;
  current_label?: string;
};

export type StatusBannerDataV2 = {
  description?: string;
  heading?: string;
  currentLabel?: string;
  actionButtonLabel?: string;
  actionButtonUrl?: string;
  image?: GDSIconProps['icon'];
  analyticsProps?: EventProps;
};

export const StatusBannerMasonryWidgetV1 = ({
  widget,
}: {
  widget: ContainerWidgetItem;
}) => {
  const {
    data: widgetData,
    isLoading,
    error,
  } = useGetWidgetData<StatusBannerData>(widget, MASONRY.API_VERSION);

  if (isLoading) {
    return <SkeletonBox height="60px" />;
  }

  if (!widgetData || error) {
    return null;
  }

  return (
    <StatusBanner
      title={widgetData.attributes.heading}
      status={widgetData.attributes.current_label as GDSStatus}
    >
      <ParagraphText size="sm">
        {widgetData.attributes.description}
      </ParagraphText>
    </StatusBanner>
  );
};

export const StatusBannerMasonryWidgetV2 = ({
  description,
  heading,
  currentLabel,
  actionButtonLabel,
  actionButtonUrl,
  analyticsProps,
  image,
}: StatusBannerDataV2) => {
  const isExternalLink = isExternalUrl(actionButtonUrl);
  const handleLink = useConfigProperty('core.customMethods.handleLink');
  return (
    <StatusBanner
      title={heading}
      showIcon={Boolean(image)}
      status={(currentLabel as GDSStatus) || 'info'}
      icon={image && <Icon icon={image} size="$oneAndHalf" />}
    >
      <ParagraphText
        size="sm"
        css={{
          color: '$onSurfaceTextPrimary',
          marginTop: '$half',
        }}
      >
        {description}
      </ParagraphText>
      {actionButtonUrl && (
        <TextAction
          size="medium"
          as={isExternalLink ? 'a' : Link}
          // @ts-ignore-next-line
          to={isExternalLink ? undefined : actionButtonUrl}
          href={isExternalLink ? actionButtonUrl : undefined}
          target={isExternalLink ? '_blank' : undefined}
          rel={isExternalLink ? 'noopener noreferrer' : undefined}
          onClick={(e: React.MouseEvent) => {
            if (isExternalLink) {
              e.preventDefault();
              handleLink(actionButtonUrl);
            }
            trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
              ...analyticsProps,
              detail: actionButtonLabel,
            });
          }}
          css={{
            marginBlock: '$half',
            width: 'fit-content',
          }}
        >
          {actionButtonLabel}
        </TextAction>
      )}
    </StatusBanner>
  );
};
